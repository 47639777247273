import kebabCase from 'lodash/kebabCase'
import { lazy, Suspense } from 'react'

import LazyHydrate from '~/components/shared/LazyHydrate'
import Responsive from '~/components/shared/Responsive'
import { FAQPageProps } from '~/pages/faq'
import { cx } from '~/utils'

import FaqItem from '../FaqItem'

import * as containerStyles from './FaqSection.module.scss'

const FaqSideNavigation = lazy(() => import('../FaqSideNavigation'))

const FaqSection = ({ data }: FAQPageProps) => (
  <div className={cx(containerStyles.faqSection, 'faqPageSection')}>
    <div className="container container-md">
      <div className="row">
        <div className="col-lg-8 toc-content">
          {data.allStrapiCompanyfaqsection.edges.map(
            ({ node: faqsection }, index) => (
              <div
                key={faqsection.id}
                className={containerStyles.faqSection__wrapper}
              >
                {faqsection.header_section && (
                  <h2
                    id={`${kebabCase(faqsection.header_section)}`}
                    className={containerStyles.faqSection__header}
                  >
                    {faqsection.header_section}
                  </h2>
                )}

                <h2
                  className={containerStyles.faqSection__title}
                  id={`${kebabCase(faqsection.title as string | undefined)}`}
                >
                  {faqsection.title}
                </h2>

                {index <= 1 ? (
                  <FaqItem data={faqsection} />
                ) : (
                  <LazyHydrate whenVisible>
                    <FaqItem data={faqsection} />
                  </LazyHydrate>
                )}
              </div>
            ),
          )}
        </div>

        <div className="col-lg-2">
          <Responsive breakpoint="mdUp">
            <Suspense>
              <FaqSideNavigation
                strapiCta={data.strapiCta as Queries.STRAPI_CTA | undefined}
              />
            </Suspense>
          </Responsive>
        </div>
      </div>
    </div>
  </div>
)

export default FaqSection
