import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'

import SEO from '~/components/shared/Seo'
import {
  AllStrapiCompanyfaqsection,
  QueryResult,
  StrapiCta,
  StrapiPage,
} from '~/types/graphql'
import { WithRequired } from '~/types/helper'
import { fileToImageLikeData } from '~/utils'
import FAQ from '~/views/FAQ/FAQ.view'

export type FAQPageProps = QueryResult<
  StrapiPage & AllStrapiCompanyfaqsection & StrapiCta
>

const FAQPage = ({ data }: FAQPageProps) => <FAQ data={data} />

export default FAQPage

export const query = graphql`
  query {
    strapiPage(page_name: { eq: "FAQ" }) {
      ...MetaTagInformationPages
    }

    allStrapiCompanyfaqsection(sort: { position_section: ASC }) {
      edges {
        node {
          id
          title
          header_section
          position_section
          companyfaqs {
            answer {
              data {
                childMarkdownRemark {
                  html
                }
              }
            }
            question
          }
        }
      }
    }

    strapiCta(pages: { elemMatch: { page_name: { eq: "FAQ" } } }) {
      link
      wrapper_image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 290
              formats: [AUTO, WEBP]
              placeholder: BLURRED
            )
          }
        }
      }
    }
  }
`

export const Head = ({ data }: FAQPageProps) => {
  const { meta_title, meta_description, og_image, structured_data } =
    data.strapiPage as WithRequired<
      Queries.STRAPI_PAGE,
      'meta_title' | 'og_image'
    >

  return (
    <SEO
      title={meta_title}
      description={meta_description}
      urlImage={getSrc(fileToImageLikeData(og_image?.localFile)) as string}
      structuredDataFAQPage={structured_data as string | undefined}
      url="https://www.codica.com/faq/"
    />
  )
}
