import MainLayout from '~/components/layouts/MainLayout'
import PrimeContactFormSection from '~/components/sections/PrimeContactFormSection'
import PrimeSection from '~/components/sections/PrimeSection'
import { FAQPageProps } from '~/pages/faq'
import FaqSection from '~/views/FAQ/components/FaqSection'

const FAQ = ({ data }: FAQPageProps) => (
  <MainLayout isTransparent>
    <div className="faqPage">
      <PrimeSection
        isSubtractBlock
        bgGradient="gradientFaqPage"
        title="Frequently Asked Questions"
        description="Questions about Codica: our company, clients, services, workflows, and warranties for the software solutions we provide."
      />
      <FaqSection data={data} />
      <PrimeContactFormSection title="Let’s turn your idea into a successful product!" />
    </div>
  </MainLayout>
)

export default FAQ
