import kebabCase from 'lodash/kebabCase'
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'

import { WithRequired } from '~/types/helper'

import '~/components/blocks/FAQ/FaqAccordion/FaqAccordion.scss'
import './FaqItem.scss'

interface FaqItemProps {
  data: Queries.STRAPI_COMPANYFAQSECTION
}

const FaqItem = ({ data }: FaqItemProps) => {
  const companyfaqs = data.companyfaqs as WithRequired<
    Queries.STRAPI_COMPANYFAQ,
    'question' | 'answer'
  >[]

  return (
    <Accordion className="accordionCard" allowZeroExpanded>
      {companyfaqs.map(({ question, answer }) => (
        <AccordionItem className="accordionCard__item" key={question}>
          <AccordionItemHeading>
            <AccordionItemButton className="accordionCard__button">
              <h3
                className="accordionCard__button-text"
                id={`${kebabCase(question)}`}
              >
                {question}
              </h3>
              <div className="accordionCard__button-arrow">
                <div className="accordionCard__button-line" />
                <div className="accordionCard__button-line vertical" />
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="accordionCard__panel">
            <div
              dangerouslySetInnerHTML={{
                __html: answer.data?.childMarkdownRemark?.html,
              }}
            />
          </AccordionItemPanel>
        </AccordionItem>
      ))}
    </Accordion>
  )
}
export default FaqItem
